'use client';
/*
 * Documentation:
 * Signup Request Dialog — https://app.subframe.com/library?component=Signup+Request+Dialog_b2823029-f6a3-4593-9bf5-457dd587dd10
 */

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IconButton } from 'subframe/components/IconButton';
import { TextField } from 'subframe/components/TextField';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { SetStateFunction } from 'models/react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/browser';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import FormProvider from 'components/FormProvider';
import styles from './SignupRequestDialog.module.scss';
import { axiosInstance } from 'api/axios-instance';
import {
  fieldIsRequiredErrorMessage,
  invalidNameInputErrorMessage,
  validNameRegex,
} from 'constants/input-validation';

interface Props {
  open: boolean;
  handleOpenChange: SetStateFunction<boolean>;
}

interface SignupRequestFields {
  firstname: string;
  email: string;
  jobtitle: string;
}
interface HubspotSignupRequestFormField {
  name: string;
  value: string;
}
interface HubspotSignupRequestPayload {
  portalId: string;
  formGuid: string;
  fields: HubspotSignupRequestFormField[];
}

export const SignupRequestDialog = ({ open, handleOpenChange }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const HSPortalId = '21736838';
  const HSFormGuid = '6cb1ad36-fccb-4579-b6c1-f2b87758987b';
  const HSSubmissionUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${HSPortalId}/${HSFormGuid}`;
  const [loading, setLoading] = useState(false);
  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'submitting your signup request',
  );

  const FormSchema = Yup.object().shape({
    firstname: Yup.string().required(fieldIsRequiredErrorMessage('Name')),
    email: Yup.string()
      .required(fieldIsRequiredErrorMessage('Email'))
      .email('Email is invalid'),
    jobtitle: Yup.string().required(fieldIsRequiredErrorMessage('Job Title')),
  });

  const defaultValues: SignupRequestFields = {
    firstname: '',
    email: '',
    jobtitle: '',
  };

  const methods = useForm<SignupRequestFields>({
    resolver: yupResolver(FormSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const sendSignupRequest = async (data: SignupRequestFields) => {
    const fields: HubspotSignupRequestFormField[] = Object.entries(data).map(
      ([key, value]) => {
        return {
          name: key,
          value: value,
        };
      },
    );
    const reqBody: HubspotSignupRequestPayload = {
      portalId: HSPortalId,
      formGuid: HSFormGuid,
      fields: fields,
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    // makes axios call to submit signup request using HSSubmissionUrl
    return await axiosInstance.post(HSSubmissionUrl, reqBody, { headers });
  };

  const onSubmit = async (data: SignupRequestFields) => {
    try {
      setLoading(true);
      await sendSignupRequest(data);
      enqueueSnackbar('Thanks for signing up. We will be in touch shortly', {
        variant: 'success',
      });
    } catch (error) {
      Sentry.captureException(error, { extra: { data } });
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    } finally {
      handleOpenChange(false);
      methods.reset();
      setLoading(false);
    }
  };

  // clear form on close of dialog using useeffect
  useEffect(() => {
    if (!open) {
      methods.reset();
    }
  }, [open]);

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <Dialog.Content>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles['stack-daacb36e']}>
            <div className={styles['stack']}>
              <span className={styles['text']}>Signup Request</span>
              <IconButton
                icon="FeatherX"
                size="medium"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  methods.reset();
                  handleOpenChange(false);
                }}
              />
            </div>
            <TextField
              className="flex-[0_0_auto] h-auto w-full"
              label="Name"
              error={methods.getFieldState('firstname').invalid}
              helpText={errors.firstname?.message}
            >
              <TextField.Input
                placeholder="Enter your name"
                className="flex-[0_0_auto] h-[32px] w-full"
                {...methods.register('firstname')}
              />
            </TextField>
            <TextField
              className="flex-[0_0_auto] h-auto w-full"
              label="Work Email"
              error={methods.getFieldState('email').invalid}
              helpText={errors.email?.message}
            >
              <TextField.Input
                placeholder="Enter your work email"
                className="flex-[0_0_auto] h-[32px] w-full"
                {...methods.register('email')}
              />
            </TextField>
            <TextField
              className="flex-[0_0_auto] h-auto w-full"
              label="Job Title"
              error={methods.getFieldState('jobtitle').invalid}
              helpText={errors.jobtitle?.message}
            >
              <TextField.Input
                placeholder="Enter your current job title"
                className="flex-[0_0_auto] h-[32px] w-full"
                {...methods.register('jobtitle')}
              />
            </TextField>
            <div
              className={classNames('page-contents', styles['stack-450cf5e6'])}
            >
              <Button
                size="large"
                variant="brand-primary"
                loading={loading}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
};
