export type Severity = 'critical' | 'high' | 'medium' | 'low';
export type SeverityBadge = 'Critical' | 'High' | 'Medim' | 'Low';

type SeverityBadgeMapType =
  | 'brand'
  | 'neutral'
  | 'error'
  | 'warning'
  | 'success';

export const severityBadgeMap: Record<string, SeverityBadgeMapType> = {
  critical: 'error',
  high: 'brand',
  medium: 'warning',
  low: 'success',
};

export type JiraPriority =
  | 'highest'
  | 'high'
  | 'medium'
  | 'low'
  | 'very-low'
  | 'lowest';

export type ARViews = 'example' | 'real';

export enum ClustersViewLayout {
  GRID = 'grid',
  LIST = 'list',
}
