import * as Sentry from '@sentry/react';
import { formatDistanceToNow } from 'date-fns';
import React from 'react';
import { Avatar, IconWithBackground } from 'subframe/index';
import { AtomicTooltip } from './AtomicTooltip';

interface TooltipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  comment: string;
  userName?: string;
  time?: number;
  picture?: string;
  action?: string;
  children?: React.ReactNode;
}

const TooltipCommentRoot = React.forwardRef<HTMLElement, TooltipRootProps>(
  function TooltipRoot(
    {
      comment,
      userName,
      time,
      picture,
      action,
      children,
      ...otherProps
    }: TooltipRootProps,
    ref,
  ) {
    if (!!!userName || !!!time) {
      Sentry.captureException(
        {
          error: 'User information missing for comment tooltip.',
          userName: userName,
          time: time,
          picture: picture,
        },
        {
          level: 'log',
        },
      );

      return <></>;
    }
    return (
      <AtomicTooltip
        tooltipContent={
          <div style={{ display: 'flex', gap: '8px' }}>
            <div>
              {picture ? (
                <Avatar
                  size="x-small"
                  image={picture}
                  className="ph-no-capture"
                />
              ) : (
                <Avatar size="x-small" variant="brand">
                  {userName.charAt(0).toUpperCase()}
                </Avatar>
              )}
            </div>
            <span>
              <span className="ph-no-capture">
                {`${userName || ''} ${action ? action : 'commented'} `}
              </span>
              {formatDistanceToNow(new Date(time * 1000), { addSuffix: true })}:
              &quot;
              {comment}
              &quot;
            </span>
          </div>
        }
      >
        {children || <IconWithBackground icon="FeatherMessageSquare" />}
      </AtomicTooltip>
    );
  },
);

export const CommentTooltip = TooltipCommentRoot;
