import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Sentry from '@sentry/browser';
import { addTeamMember } from 'api/frontend';
import { AddTeamMemberRequest } from 'api/models';
import FormProvider from 'components/FormProvider';
import { fieldIsRequiredErrorMessage } from 'constants/input-validation';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useUserAccountState from 'src/hooks/useUserAccountState';
import AnalyticsEventLogger from 'src/utils/AnalyticsEventLogger';
import { Button } from 'subframe/components/Button';
import { Dialog } from 'subframe/components/Dialog';
import { IconButton } from 'subframe/components/IconButton';
import { TextField } from 'subframe/index';
import * as Yup from 'yup';

interface Props {
  open: boolean;
  handleOpenChange: (b: boolean) => void;
  memberEmail?: string;
}
interface InviteMembersToTeamFields {
  email: string;
}

export const InviteTeamMemberDialog = ({
  open,
  handleOpenChange,
  memberEmail,
}: Props) => {
  const { account, currentOrganization } = useUserAccountState();
  const { logEvent } = AnalyticsEventLogger();
  const { enqueueSnackbar } = useSnackbar();
  const InviteMemberToTeamSchema = Yup.object().shape({
    email: Yup.string()
      .required(fieldIsRequiredErrorMessage('Email'))
      .email('Email is invalid'),
  });
  const SubmissionError = somethingWentWrong.replace(
    '<action>',
    'inviting this team member',
  );

  const defaultValues: InviteMembersToTeamFields = {
    email: memberEmail || '',
  };

  const methods = useForm<InviteMembersToTeamFields>({
    resolver: yupResolver(InviteMemberToTeamSchema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    if (!open) {
      methods.reset(defaultValues);
    }
  }, [open]);

  const onSubmit = async (data: InviteMembersToTeamFields) => {
    try {
      const reqBody: AddTeamMemberRequest = {
        member_email: data.email,
      };
      const headers = {
        Authorization: `Bearer ${account?.token}`,
      };
      await addTeamMember('default', 'default', reqBody, {
        headers,
      });
      enqueueSnackbar('Team member invited successfully', {
        variant: 'success',
      });
      logEvent('team-member-invite-sent', data);
      handleOpenChange(false);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(SubmissionError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <Dialog.Content>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-6 items-center justify-end flex-[1_0_0] h-full w-[448px] p-4">
            <div className="flex justify-between items-center w-full">
              <span className="flex-[1_0_0] w-full text-default-font text-subheader">
                Invite New Member
              </span>
              <IconButton
                icon="FeatherX"
                size="medium"
                onClick={() => handleOpenChange(false)}
              />
            </div>
            <div className="flex flex-col gap-4 items-start flex-[1_0_0] h-full w-full pl-0 pr-3 py-0">
              <TextField
                className="h-auto w-full flex-none"
                label="Enter Email"
                helpText={errors.email?.message}
                error={methods.getFieldState('email').invalid}
              >
                <TextField.Input
                  placeholder="example@domain.com"
                  {...methods.register('email')}
                />
              </TextField>
              <div className="flex w-full gap-4 items-end justify-center">
                <Button
                  size="large"
                  variant="brand-primary"
                  icon={null}
                  loading={isSubmitting}
                  onClick={handleSubmit(onSubmit)}
                  disabled={isSubmitting}
                >
                  Send Invite
                </Button>
              </div>
            </div>
          </div>
        </FormProvider>
      </Dialog.Content>
    </Dialog>
  );
};
