'use client';

import React from 'react';
import * as SubframeCore from '@subframe/core';
import { SkeletonText } from './SkeletonText';

interface RiskCardRootProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  icon?: SubframeCore.IconName;
  needAttentionTooltip?: React.ReactNode;
  numRisks?: string;
  inactive?: boolean;
  loading?: 'default';
  selected?: boolean;
  loadingValue?: boolean;
  needAttention?: React.ReactNode;
  className?: string;
}

const RiskCardRoot = React.forwardRef<HTMLElement, RiskCardRootProps>(
  function RiskCardRoot(
    {
      title,
      icon = 'FeatherXSquare',
      needAttentionTooltip,
      numRisks,
      inactive = false,
      loading = 'default',
      selected = false,
      loadingValue = false,
      needAttention,
      className,
      ...otherProps
    }: RiskCardRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'group/deb9e8aa flex h-40 w-80 cursor-pointer flex-col items-start gap-4 rounded-md border border-solid border-neutral-border bg-neutral-50 px-6 py-6 hover:bg-neutral-100',
          {
            'border border-solid border-brand-primary': selected,
            'bg-neutral-200': inactive,
          },
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        <div className="flex w-full items-center gap-2">
          <SubframeCore.Icon
            className={SubframeCore.twClassNames(
              'text-subheader font-subheader text-default-font',
              { 'text-neutral-50': inactive },
            )}
            name={icon}
          />
          {title ? (
            <span
              className={SubframeCore.twClassNames(
                'text-subheader font-subheader text-default-font',
                { 'text-neutral-50': inactive },
              )}
            >
              {title}
            </span>
          ) : null}
        </div>
        <div className="flex flex-col items-start gap-1">
          {needAttention ? (
            <div className="flex items-center gap-2">{needAttention}</div>
          ) : null}
          <div
            className={SubframeCore.twClassNames('flex items-center gap-2', {
              'h-auto w-6 flex-none': loadingValue,
            })}
          >
            {numRisks ? (
              <span
                className={SubframeCore.twClassNames(
                  'text-section-header font-section-header text-default-font',
                  { hidden: loadingValue, 'text-neutral-50': inactive },
                )}
              >
                {numRisks}
              </span>
            ) : null}
            <SkeletonText size="section-header" />
          </div>
        </div>
      </div>
    );
  },
);

export const RiskCard = RiskCardRoot;
