import { SubscriptionPlan } from 'api/models';
import { submitFeedback } from 'api/frontend';
import useUserAccountState from 'hooks/useUserAccountState';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { useSnackbar } from 'notistack';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/react';

interface Props {
  className?: string;
  view?: 'Subscription Plan' | 'top-bar';
  plan?: SubscriptionPlan | 'AdditionalQuota';
}

export const ProductPlanDemo = ({ className, view, plan }: Props) => {
  const { account, user, currentOrganization } = useUserAccountState();

  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = AnalyticsEventLogger();

  const submitDemoRequest = async () => {
    logEvent('product-plans-free-trial-request', {
      view: view || '',
      planRequest: plan || '',
    });
    try {
      await submitFeedback(
        {
          title: 'ContactUsPricingRequest',
          details: JSON.stringify({
            request:
              '🔔 Heads up, a customer 🙋‍♂️🙋‍♀️ just requested for Free Trial',
            accountId: account?.accountId,
            orgId: currentOrganization?.id,
            view: view || '',
            planRequest: plan || '',
          }),
          user_email: user.email || user.userId,
        },
        {
          headers: {
            Authorization: `Bearer ${account.token}`,
          },
        },
      );

      enqueueSnackbar(
        `Thank you for contacting us. We will be in touch with you shortly.`,
        {
          variant: 'success',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    } catch (err) {
      Sentry.captureException(err);
      enqueueSnackbar(
        somethingWentWrong.replace('<action>', 'requesting Subscription'),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
    }
  };
  return (
    <span
      className="hoverable-link text-label font-label cursor-pointer"
      onClick={() => {
        submitDemoRequest();
      }}
    >
      Start your Free Trial today
    </span>
  );
};
