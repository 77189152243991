import {
  ClusterDetectedLar,
  ClusterScannedSignature,
  SignatureLabel,
} from 'api/models';

export enum Category {
  DEFECTS = 'Defects',
  MISCONFIGURATIONS = 'Misconfigurations',
  SYSTEM_REQUIREMENTS = 'SystemRequirements',
  DEPRECATIONS = 'Deprecations',
  UNSUPPORTED_VERSIONS = 'UnsupportedVersions',
  VERSION_INCOMPATABILITIES = 'VersionIncompatibilities',
  BEST_PRACTICES = 'BestPractices',
}

export const filterLARs = (lars: ClusterDetectedLar[] | undefined) => {
  return lars?.filter(
    (lar) => !lar.labels || !findCategory(lar?.labels, Category.BEST_PRACTICES),
  );
};
export const filterSignatures = (
  signatures: ClusterScannedSignature[] | undefined,
) => {
  return signatures?.filter(
    (sig) => !sig.labels || !findCategory(sig?.labels, Category.BEST_PRACTICES),
  );
};

export const filterSignaturesOnCategory = (
  signatures: ClusterScannedSignature[] | undefined,
  filterCategory: string,
) => {
  return signatures?.filter((sig) =>
    (sig.labels?.length || 0) > 0
      ? findCategory(sig?.labels, filterCategory)
      : false,
  );
};

export const filterLARsOnCategory = (
  lars: ClusterDetectedLar[] | undefined,
  filterCategory: string,
) => {
  return lars?.filter((lar) =>
    (lar.labels?.length || 0) > 0
      ? findCategory(lar?.labels, filterCategory)
      : false,
  );
};

export const findCategory = (
  labels: SignatureLabel[],
  filterCategory: string,
) => {
  for (let l = 0; l < labels.length; l++) {
    if (labels[l]?.category === filterCategory) {
      return true;
    }
  }
  return false;
};

export const getCategory = (labels: SignatureLabel[]) => {
  for (let l = 0; l < labels.length; l++) {
    if (labels[l]?.category) {
      return clearSensitivity(labels[l]?.category) === 'bestpractices'
        ? 'Guardrails'
        : labels[l]?.category;
    }
  }
  return '';
};

export const DetectedTerm = (type: string | '') => {
  switch (clearSensitivity(type)) {
    case 'guardrails':
      return 'Not Followed';

    case 'systemrequirements':
      return 'Not Met';

    default:
      return 'Detected';
  }
};

export const NotDetectedTerm = (type: string | '') => {
  switch (clearSensitivity(type)) {
    case 'guardrails':
      return 'Followed';

    case 'systemrequirements':
      return 'Met';

    default:
      return 'Not Detected';
  }
};

export const ARSigsSeverityCounts = (UnignoredLARs: ClusterDetectedLar[]) => {
  const data = {
    UnignoredLARsCount: UnignoredLARs.length,
    NACount: 0,
    Critical: 0,
    High: 0,
    Medium: 0,
    Low: 0,
  };

  for (let i = 0; i < UnignoredLARs.length; i++) {
    const lar = UnignoredLARs[i];
    const lowerCaseSev = lar.severity.toLowerCase();
    switch (lowerCaseSev) {
      case 'critical':
        data.Critical += 1;
        break;
      case 'high':
        data.High += 1;
        break;
      case 'medium':
        data.Medium += 1;
        break;
      case 'low':
        data.Low += 1;
        break;
    }
    if (lar.need_attention) {
      data.NACount += 1;
    }
  }
  return data;
};

export type clusterDetailsTableView =
  | 'NeedsAttention'
  | 'Detected'
  | 'UnDetected'
  | 'Ignored';

const getClusterDetailsTooltipPrefix = (category: string) => {
  return `Number of ${category} that Need Attention. `;
};
const getDetectedClusterDetailsTooltipPrefix = (category: string) => {
  return `Number of ${category} that were detected. `;
};
enum NeedAttentionTooltipMesg {
  unsupportedversions = 'Includes all add-ons that have reached end of support and cluster versions reaching end of support in <30 days.',
  systemrequirements = '',
  deprecations = 'Includes APIs and/or software that will be Deprecated when the cluster or an add-on is upgraded.',
  versionincompatibilities = 'Includes all Incompatible Versions reaching end of support by a cloud provider or another add-on.',
  defects = 'Includes Critical & High Risks while excluding Ignored Defects.',
  misconfigurations = 'Includes Critical & High Risks while excluding Ignored Misconfigurations.',
  default = 'Includes Critical & High Risks while excluding Ignored Risks.',
}

enum DetectedTooltipMesg {
  unsupportedversions = 'Includes all add-ons that have reached end of support and cluster versions reaching end of support in <30 days.',
  systemrequirements = 'Includes all unmet System Requirements detected by Chkk excluding ignored Risks.',
  deprecations = 'Includes APIs and/or software that will be Deprecated when the cluster or an add-on is upgraded.',
  versionincompatibilities = 'Includes all Incompatible Versions reaching end of support by a cloud provider or another add-on.',
  defects = 'Includes issues causing unexpected behaviors, such as crashes or service interruptions, due to errors in code or resource management that may impact system stability.',
  misconfigurations = 'Includes issues resulting from incorrect settings or configurations that can disrupt normal operations, which may prevent upgrades, cause component failures, or lead to system downtime.',
  default = 'Includes all Risks detected by Chkk while excluding Ignored.',
}

export const clearSensitivity = (targetString: string) => {
  return targetString.replace(/-/g, '').toLowerCase().split(' ').join('');
};

export const splitStringAtUppercase = (targetString: string) => {
  return targetString.replace(/([A-Z])/g, ' $1').trim();
};

export const convertToLowerCaseWithDashes = (targetString: string) => {
  return targetString
    .replace(/([A-Z])/g, '$1')
    .replace(' ', '-')
    ?.toLowerCase();
};

export const getNeedAttentionTooltip = (category: string) => {
  switch (clearSensitivity(category)) {
    case 'systemrequirements':
      return getClusterDetailsTooltipPrefix('unmet System Requirements');
    case 'deprecations':
      return (
        getClusterDetailsTooltipPrefix('Deprecations') +
        NeedAttentionTooltipMesg.deprecations
      );
    case 'unsupportedversions':
      return (
        getClusterDetailsTooltipPrefix('Unsupported Versions') +
        NeedAttentionTooltipMesg.unsupportedversions
      );
    case 'versionincompatibilities':
      return (
        getClusterDetailsTooltipPrefix('Version Incompatibilities') +
        NeedAttentionTooltipMesg.versionincompatibilities
      );
    case 'misconfigurations':
      return (
        getClusterDetailsTooltipPrefix('Misconfigurations') +
        NeedAttentionTooltipMesg.misconfigurations
      );
    case 'defects':
      return (
        getClusterDetailsTooltipPrefix('Defects') +
        NeedAttentionTooltipMesg.defects
      );
    default:
      return NeedAttentionTooltipMesg.default;
  }
};

export const getDetectedTooltip = (
  category: keyof typeof DetectedTooltipMesg,
) => {
  switch (clearSensitivity(category)) {
    case 'systemrequirements':
      return getDetectedClusterDetailsTooltipPrefix(
        'unmet System Requirements',
      );
    case 'deprecations':
      return DetectedTooltipMesg.deprecations;
    case 'unsupportedversions':
      return DetectedTooltipMesg.unsupportedversions;
    case 'versionincompatibilities':
      return DetectedTooltipMesg.versionincompatibilities;
    case 'misconfigurations':
      return DetectedTooltipMesg.misconfigurations;
    case 'defects':
      return DetectedTooltipMesg.defects;
    default:
      return DetectedTooltipMesg.default;
  }
};
export enum ClusterDetailsFilterView {
  NeedAttention = 'NeedsAttention',
  Detected = 'Detected',
  UnDetected = 'UnDetected',
  Ignored = 'Ignored',
}
