'use client';
/*
 * Documentation:
 * Tooltip — https://app.subframe.com/cd4f5307efe3/library?component=Tooltip_ccebd1e9-f6ac-4737-8376-0dfacd90c9f3
 */

import React from 'react';
import * as SubframeCore from '@subframe/core';

interface TooltipRootProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: string;
  className?: string;
}

const TooltipRoot = React.forwardRef<HTMLElement, TooltipRootProps>(
  function TooltipRoot(
    { children, className, ...otherProps }: TooltipRootProps,
    ref,
  ) {
    return (
      <div
        className={SubframeCore.twClassNames(
          'flex flex-col items-start gap-2 rounded-md border border-solid border-neutral-900 bg-neutral-800 px-2 py-1 shadow-lg',
          className,
        )}
        ref={ref as any}
        {...otherProps}
      >
        {children ? (
          <span className="text-label font-label text-black">{children}</span>
        ) : null}
      </div>
    );
  },
);

export const Tooltip = TooltipRoot;
