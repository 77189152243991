import React, { useState } from 'react';
import semver from 'semver';
// components
import {
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import TextView from '../components/TextView';
import TagLabel from './TagLabel';
import Iconify from './Iconify';
import TextFieldCustom from './TextFieldCustom';
import { ChangeClusterNameEnvironmentDialog } from 'pages/clusters/dialogs/ChangeClusterNameEnvironmentDialog';

// hooks
import { useTheme } from '@mui/material/styles';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

import { addMinutes, format } from 'date-fns';
import { Spacer } from './Spacer';
import { AtomicTooltip } from 'components/design-system';
import styles from './ClusterProperties.module.scss';
import { Switch } from 'subframe/index';
import { useSnackbar } from 'notistack';
import {
  AvailabilityRiskSeverity,
  Cluster,
  ClusterDetectedLar,
  ClusterScan,
  ClusterStatus,
} from 'api/models';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useUserAccountState from 'hooks/useUserAccountState';
import { Loader } from '@subframe/core';
import { KeyedMutator } from 'swr';
import { updateCluster } from 'api/frontend';
import { somethingWentWrong, toastAutoHideDuration } from 'constants/toasts';
import * as Sentry from '@sentry/browser';
import { ResponseType } from 'api/axios-instance';

interface ClusterPropertiesProps {
  alignment?: string | null;
  properties: any;
  scan?: ClusterScan;
  filteredLARs?: ClusterDetectedLar[];
  onClusterNameEnvironmentChange: (
    newName: string,
    newEnvironment: string,
  ) => void;
  mutateCluster: KeyedMutator<ResponseType<Cluster>>;
}

const ClusterProperties: React.FC<ClusterPropertiesProps> = ({
  properties,
  scan,
  filteredLARs,
  onClusterNameEnvironmentChange,
  mutateCluster,
}) => {
  const theme = useTheme();
  const { account } = useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();

  //show properties for chkk_metadata.version v1.0.26-v1.0.35, and v0.1.1 onwards.
  let versionUpdated = false;
  if (properties?.chkk_metadata?.version) {
    versionUpdated =
      properties.chkk_metadata.version?.substring(0, 2) === 'v1'
        ? semver.gte(properties.chkk_metadata.version, 'v1.0.26')
        : semver.gte(properties.chkk_metadata.version, 'v0.1.1');
  }

  //timestamp -> UTC
  let cluster_created = properties?.created
    ? new Date(properties.created * 1000)
    : undefined;
  let cc_formatted = cluster_created
    ? format(
        addMinutes(cluster_created, cluster_created.getTimezoneOffset()),
        'PPp',
      ) + ' UTC'
    : '-';

  let last_scan = scan?.created ? new Date(scan.created * 1000) : undefined;
  let ls_formatted = last_scan
    ? format(addMinutes(last_scan, last_scan.getTimezoneOffset()), 'PPp') +
      ' UTC'
    : '-';

  //if cloud provider data not present, show "K8s"
  let provider = 'k8s';
  if (properties?.overrides?.infrastructure?.cloud_platform) {
    provider = properties.overrides.infrastructure.cloud_platform;
  } else if (properties?.cloud_provider) {
    provider = properties.cloud_provider;
  }

  //dynamic labels based on api response
  let labels_array: Array<string> = [];
  for (let l in properties?.labels) {
    labels_array.push(l.toString() + ': ' + properties.labels[l].toString());
  }

  //lars count for each type
  let lars_data = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  };
  if (filteredLARs !== undefined) {
    for (let i = 0; i < filteredLARs.length; i++) {
      let lar_type = filteredLARs[
        i
      ].severity.toLowerCase() as AvailabilityRiskSeverity;
      lars_data[lar_type] += 1;
    }
  }

  //list of inclusion/exclusion rules of form
  let rules = 'No rules defined.';
  if (
    properties?.chkk_metadata?.config &&
    properties?.chkk_metadata?.config['clusterguard.config']
  ) {
    if (properties?.chkk_metadata?.config['clusterguard.config'] !== '\n') {
      rules = properties.chkk_metadata.config['clusterguard.config'];
    }
  }

  const [nameEnvironmentDialogOpen, setNameEnvironmentDialogOpen] =
    useState(false);

  const SAMPLE_ROUTE = 'clusters/new';

  const basePath = useAccountIdRoute(`/orgs/:orgId/accounts/:accountId/`);

  const newPath = `${basePath}${SAMPLE_ROUTE}`;
  const [showLoading, setShowLoading] = useState(false);
  const { logEvent } = AnalyticsEventLogger();

  const updateClusterStatus = async (
    cluster: Cluster,
    newStatus: ClusterStatus,
  ) => {
    const action = newStatus === 'active' ? 'activate' : 'deactivate';
    setShowLoading(true);
    logEvent('update-cluster-status', {
      clusterId: cluster.id,
      newStatus: newStatus,
    });
    try {
      const headers = {
        Authorization: `Bearer ${account?.token}`,
      };
      await updateCluster(
        cluster.id,
        {
          status: newStatus,
        },
        { headers },
      );

      enqueueSnackbar(`Cluster ${action}d successfully`, {
        variant: 'success',
        autoHideDuration: toastAutoHideDuration,
      });
      await mutateCluster();
      setShowLoading(false);
    } catch (error) {
      Sentry.captureException(error);
      enqueueSnackbar(
        somethingWentWrong.replace(
          '<action>',
          action === 'activate' ? 'activating' : 'deactivating',
        ),
        {
          variant: 'error',
          autoHideDuration: toastAutoHideDuration,
        },
      );
      setShowLoading(false);
    }
  };

  const clusterPropertiesChildren = (
    <Box
      id="cluster-cluster-properties"
      sx={{
        background: theme.palette.grey[900],
        mt: theme.spacing(2),
        borderRadius: 1,
        padding: theme.spacing(3),
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingBottom: theme.spacing(3),
          }}
        >
          Cluster Properties
        </Typography>
      </Box>

      <ChangeClusterNameEnvironmentDialog
        clusterDetails={{
          id: properties?.id,
          name: properties?.name,
          environment: properties?.environment || '',
        }}
        allowNameChange={
          properties?.agent_managed_properties?.name === undefined
        }
        allowEnvironmentChange={
          properties?.agent_managed_properties?.environment === undefined
        }
        onClusterNameEnvironmentChange={onClusterNameEnvironmentChange}
        open={nameEnvironmentDialogOpen}
        onClose={() => setNameEnvironmentDialogOpen(false)}
      />
      <Grid container columnSpacing={{ sm: 2, md: 15 }}>
        <Grid item sm={12} md={6}>
          <TextView label="Cluster Name">
            <AtomicTooltip tooltipContent={properties?.name}>
              <Typography
                id="cluster-properties-name"
                variant="body2"
                className={styles['text-limit']}
              >
                {properties?.name}
              </Typography>
            </AtomicTooltip>
            <AtomicTooltip
              tooltipContent={
                properties?.agent_managed_properties?.name
                  ? `Cluster name is set in Chkk Operator IaC config; Leave it blank, or modify it directly in your IaC`
                  : 'Edit cluster name'
              }
            >
              <IconButton
                id="change-cluster-name-pencil-button"
                sx={{
                  backgroundColor: theme.palette.grey[800],
                  height: 16,
                  width: 16,
                  padding: 0,
                  marginLeft: 1.5,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (properties?.agent_managed_properties?.name) {
                    enqueueSnackbar(
                      'Cluster name is set in Chkk Operator IaC config; Leave it blank, or modify it directly in your IaC',
                      {
                        variant: 'warning',
                        autoHideDuration: toastAutoHideDuration,
                      },
                    );
                  } else {
                    setNameEnvironmentDialogOpen(true);
                  }
                }}
              >
                <Iconify icon={'akar-icons:pencil'} width={10} height={10} />
              </IconButton>
            </AtomicTooltip>
          </TextView>

          <TextView label="Environment">
            <AtomicTooltip tooltipContent={properties?.environment}>
              <Typography
                id="cluster-properties-name"
                variant="body2"
                className={styles['text-limit']}
              >
                {properties?.environment}
              </Typography>
            </AtomicTooltip>
            <AtomicTooltip
              tooltipContent={
                properties?.agent_managed_properties?.environment
                  ? `Cluster environment is set in Chkk Operator IaC config; Leave it blank, or modify it directly in your IaC`
                  : 'Edit environment'
              }
            >
              <IconButton
                id="change-cluster-name-pencil-button"
                sx={{
                  backgroundColor: theme.palette.grey[800],
                  height: 16,
                  width: 16,
                  padding: 0,
                  marginLeft: 1.5,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  if (properties?.agent_managed_properties?.environment) {
                    enqueueSnackbar(
                      'Cluster environment is set in Chkk Operator IaC config; Leave it blank, or modify it directly in your IaC',
                      {
                        variant: 'warning',
                        autoHideDuration: toastAutoHideDuration,
                      },
                    );
                  } else {
                    setNameEnvironmentDialogOpen(true);
                  }
                }}
              >
                <Iconify icon={'akar-icons:pencil'} width={10} height={10} />
              </IconButton>
            </AtomicTooltip>
          </TextView>

          <TextView label="UUID">
            <AtomicTooltip tooltipContent={properties?.id}>
              <Typography variant="body2" className={styles['text-limit']}>
                {properties?.id}
              </Typography>
            </AtomicTooltip>
          </TextView>
          <TextView label="Date Created" value={cc_formatted} />
          <Spacer value={15} />
          <TextView label="Labels">
            <Box>
              <Grid container>
                <Grid
                  item
                  sx={{
                    height: '80px',
                    width: '100%',
                    overflow: 'auto',
                    overflowY: 'scroll',
                    borderRadius: '8px',
                    float: 'right',
                    padding: '0px 0px',
                  }}
                >
                  {labels_array.map((val, idx) => (
                    <TagLabel
                      key={idx}
                      value={val}
                      sx={{
                        height: val.length > 40 ? 60 : 40,
                        marginBottom: 0.5,
                        fontSize: '0.5rem',
                      }}
                    />
                  ))}
                </Grid>
              </Grid>
            </Box>
          </TextView>
        </Grid>

        <Grid item sm={12} md={6}>
          <TextView label="Version" value={properties.version} />
          <TextView label="Provider" value={provider} />
          <TextView
            label="Location"
            value={
              properties.overrides?.infrastructure?.region
                ? properties.overrides.infrastructure.region
                : properties.region
            }
          />
          <TextView label="Nodes" value={properties.node_count} />

          <TextView label="Deactivate Cluster">
            {showLoading ? (
              <Loader />
            ) : (
              <Switch
                data-cy="cluster_view-deactivate-cluster-switch"
                checked={properties?.status === 'deactivated'}
                onClick={(event) => {
                  event.stopPropagation();

                  updateClusterStatus(
                    properties,
                    properties?.status === 'deactivated'
                      ? 'active'
                      : 'deactivated',
                  );
                }}
              />
            )}
          </TextView>
        </Grid>
      </Grid>
    </Box>
  );

  const chkkPropertiesSectionChildren = (
    <Box
      id="cluster-chkk-properties"
      sx={{
        background: theme.palette.grey[900],
        borderRadius: 1,
        padding: theme.spacing(3),
        alignItems: 'center',
        marginTop: theme.spacing(3),
      }}
    >
      <Box>
        <Typography
          variant="h2"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            paddingBottom: theme.spacing(3),
          }}
        >
          Chkk Properties
        </Typography>
        <Grid container direction={'row'}>
          <Grid container columnSpacing={{ sm: 2, md: 15 }}>
            <Grid item sm={12} md={6}>
              <TextView label="Onboarded Time" value={cc_formatted} />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextView
                idString="properties-last-scan"
                label="Last Scan"
                value={ls_formatted}
              />
            </Grid>
          </Grid>
          {properties?.chkk_metadata?.version && (
            <Grid container columnSpacing={{ sm: 2, md: 15 }}>
              <Grid item sm={12} md={6}>
                <TextView label="K8s Connector Version">
                  <span id="chkk-k8s-connector-version">
                    {properties?.chkk_metadata?.version}
                  </span>
                </TextView>
              </Grid>
            </Grid>
          )}
          <Grid item sm={12} md={12}>
            <Typography
              variant="h5"
              sx={{
                marginBottom: '10px',
              }}
            >
              Filter Rules
            </Typography>
            <TextFieldCustom
              sx={{
                height: '200px',
                width: '100%',
                background: theme.palette.grey[800],
                overflow: 'auto',
                overflowY: 'scroll',
                borderRadius: '8px',
                color: theme.palette.grey[500],
                padding: '0px 16px',
              }}
              content={rules.toString()}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const messageUpdateVersionChildren = (
    <Box
      sx={{
        background: theme.palette.grey[900],
        borderRadius: 1,
        padding: theme.spacing(3),
        alignItems: 'center',
        marginTop: theme.spacing(3),
      }}
    >
      <Box sx={{ alignItems: 'center' }}>
        <Typography>
          {
            'Cluster Properties are only available for k8s Connector version v1.0.26 and above.'
          }
          {' Update k8s Connector by clicking '}
          {<Link href={newPath}> here </Link>}
          {'.'}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth={'xl'} sx={{ pb: '10px' }}>
      {versionUpdated && clusterPropertiesChildren}
      {versionUpdated && chkkPropertiesSectionChildren}
      {!versionUpdated && messageUpdateVersionChildren}
    </Container>
  );
};

export default ClusterProperties;
