import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Tabs } from 'subframe/components/Tabs';
import * as SubframeCore from '@subframe/core';
import { Icon } from '@subframe/core';

interface TabsProps {
  tabs: {
    id: string;
    title: string | React.ReactNode;
    icon?: SubframeCore.IconName;
    content: React.ReactNode;
  }[];
  initialTabId?: string;
  onTabChange?: (newId: string) => void;
}

export function TabsWithContent(props: TabsProps): JSX.Element {
  const [tab, setTab] = useState<string>(
    props.tabs.find((t) => t.id === props.initialTabId)?.id || props.tabs[0].id,
  );

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    if (props.initialTabId) {
      setTab(props.initialTabId);
    }
  }, [props.initialTabId]);

  useLayoutEffect(() => {
    const tabsElement = tabsRef.current;
    if (!tabsElement) return;

    const updateArrowsVisibility = () => {
      setShowLeftArrow(tabsElement.scrollLeft > 0);
      setShowRightArrow(
        tabsElement.scrollLeft + tabsElement.clientWidth <
          tabsElement.scrollWidth,
      );
    };

    updateArrowsVisibility();

    window.addEventListener('resize', updateArrowsVisibility);
    tabsElement.addEventListener('scroll', updateArrowsVisibility);

    const resizeObserver = new ResizeObserver(updateArrowsVisibility);
    resizeObserver.observe(tabsElement);

    return () => {
      window.removeEventListener('resize', updateArrowsVisibility);
      tabsElement.removeEventListener('scroll', updateArrowsVisibility);
      resizeObserver.disconnect();
    };
  }, [tabsRef.current, props.tabs]);

  const scrollLeft = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollBy({ left: -150, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollBy({ left: 150, behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className="flex items-center w-full">
        {showLeftArrow && (
          <Icon
            className="hover:text-brand-600 cursor-pointer text-body font-body text-default-font ml-2 flex justify-center items-center text-lg font-bold z-10"
            name="FeatherChevronLeft"
            onClick={scrollLeft}
          />
        )}
        <div
          ref={tabsRef}
          className="flex-1 whitespace-nowrap scroll-smooth no-scrollbar mx-2"
          style={{ overflowX: 'hidden' }}
        >
          <Tabs data-cy="tabs">
            {props.tabs.map((curTab) => {
              return (
                <Tabs.Item
                  key={curTab.id}
                  data-cy={curTab.id}
                  active={tab === curTab.id}
                  icon={curTab.icon}
                  onClick={() => {
                    if (props.onTabChange !== undefined) {
                      props.onTabChange(curTab.id);
                    }
                    setTab(curTab.id);
                  }}
                  className="flex px-4 py-2"
                >
                  {curTab.title}
                </Tabs.Item>
              );
            })}
          </Tabs>
        </div>
        {showRightArrow && (
          <Icon
            className="text-body font-body text-default-font mr-2 flex justify-center items-center text-lg font-bold z-10 cursor-pointer hover:text-brand-600"
            name="FeatherChevronRight"
            onClick={scrollRight}
          />
        )}
      </div>
      <>{props.tabs.find((curTab) => curTab.id === tab)?.content}</>
    </>
  );
}
