import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import useAccountIdRoute from 'hooks/useAccountIdRoute';

// components
import { ARViews } from 'models/general';
import { convertToLowerCaseWithDashes } from 'utils/filters';
import { RouterLink } from './RouterLink';
import { Breadcrumbs, SkeletonText } from 'subframe/index';

const queryPath = {
  UnsupportedVersions: 'unsupported-versions',
  Defects: 'defects',
  Misconfigurations: 'misconfigurations',
  SystemRequirements: 'system-requirements',
  Deprecations: 'deprecations',
  VersionIncompatibilities: 'version-incompatibilities',
  Guardrails: 'guardrails',
};

type LARsBreadCrumbsHeaderProps = {
  arSigId: string;
  category: string;
  view: ARViews;
};

type MenuBreadCrumbsHeaderProps = {
  pageTitle: string;
  menuSubHeader: string;
};
type ClusterDetailsBreadCrumbsHeaderProps = {
  clusterName?: string;
  arSigId: string;
  clusterId?: string;
  category: string;
};
type LARInClusterDetailsBreadCrumbsHeaderProps = {
  clusterName?: string;
  arSigId: string;
  clusterId?: string;
  category: string;
  view: ARViews;
};

export const ClusterLARDetailsBreadCrumbsHeader = ({
  clusterName,
  arSigId,
  clusterId,
  category,
}: ClusterDetailsBreadCrumbsHeaderProps) => {
  const { logEvent } = AnalyticsEventLogger();

  const path = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/clusters');
  const clusterOverviewLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}?view=${
      category
        ? queryPath[category as keyof typeof queryPath]
        : 'arsigs-overview'
    }`,
  );

  const logEventWithDetails = (type: string) => {
    logEvent('breadcrumbs-navigation', { type, clusterId });
  };

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
      <Breadcrumbs.Divider />

      <RouterLink to={path}>
        <Breadcrumbs.Item
          data-cy="cluster-breadcrumb"
          onClick={() => {
            logEventWithDetails('clusters-view-page');
          }}
        >
          Clusters
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />

      {clusterName && clusterId ? (
        <>
          <RouterLink to={clusterOverviewLink}>
            <Breadcrumbs.Item
              data-cy="cluster-name-breadcrumb"
              onClick={() => {
                logEventWithDetails('cluster-details-page');
              }}
            >
              {clusterName}
            </Breadcrumbs.Item>
          </RouterLink>
          <Breadcrumbs.Divider />
          <Breadcrumbs.Item active={true} id="kba-signature-id">
            {arSigId}
          </Breadcrumbs.Item>
        </>
      ) : (
        <>
          <SkeletonText className="w-[200px]" />
          <Breadcrumbs.Divider />
          <SkeletonText className="w-[200px]" />
        </>
      )}
    </Breadcrumbs>
  );
};

export const LARInClusterBreadCrumbsHeader = ({
  clusterName,
  arSigId,
  clusterId,
  category,
  view,
}: LARInClusterDetailsBreadCrumbsHeaderProps) => {
  const { logEvent } = AnalyticsEventLogger();

  const path = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/availability-risks?category=${
      category && category !== 'Guardrails' ? category : 'UnsupportedVersions'
    }&view=${view}`,
  );
  const arsigOverviewLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/availability-risks/${arSigId}?view=${view}`,
  );

  const logEventWithDetails = (type: string) => {
    logEvent('breadcrumbs-navigation', { type, arSigId, clusterId });
  };

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
      <Breadcrumbs.Divider />

      <RouterLink to={path}>
        <Breadcrumbs.Item
          data-cy="fleet-breadcrumb"
          active={true}
          onClick={() => {
            logEventWithDetails('availability-risks-view-page');
          }}
        >
          Fleet
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />
      <RouterLink to={arsigOverviewLink}>
        <Breadcrumbs.Item active={true} id="kba-details-kba-id">
          {arSigId}
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />
      <Breadcrumbs.Item active={false} data-cy="kba-details-cluster-name">
        {clusterName ? clusterName : <SkeletonText className="w-[100px]" />}
      </Breadcrumbs.Item>
    </Breadcrumbs>
  );
};

export const LARsBreadCrumbsHeader = ({
  arSigId,
  category,
  view,
}: LARsBreadCrumbsHeaderProps) => {
  const { logEvent } = AnalyticsEventLogger();

  const path = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/availability-risks?category=${
      category && category !== 'Guardrails' ? category : 'UnsupportedVersions'
    }&view=${view}`,
  );

  const logEventWithDetails = (type: string) => {
    logEvent('breadcrumbs-navigation', { type, arSigId });
  };

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
      <Breadcrumbs.Divider />

      <RouterLink to={path}>
        <Breadcrumbs.Item
          data-cy="fleet-breadcrumb"
          active={true}
          onClick={() => {
            logEventWithDetails('availability-risks-view-page');
          }}
        >
          Fleet
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />
      <Breadcrumbs.Item active={true} id="kba-id-breadcrum">
        {arSigId}
      </Breadcrumbs.Item>
      <Breadcrumbs.Divider />
    </Breadcrumbs>
  );
};

export const MenuBreadCrumbsHeader = ({
  pageTitle,
  menuSubHeader,
}: MenuBreadCrumbsHeaderProps) => {
  const { logEvent } = AnalyticsEventLogger();

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>{menuSubHeader}</Breadcrumbs.Item>
      <Breadcrumbs.Divider />

      <Breadcrumbs.Item
        id={convertToLowerCaseWithDashes(pageTitle) + '-title'}
        active={true}
        onClick={() => {
          logEvent(`${pageTitle}-view-page`);
        }}
      >
        {pageTitle}
      </Breadcrumbs.Item>
    </Breadcrumbs>
  );
};

export const ClusterDetailsBreadCrumbsHeader = ({
  clusterName,
  clusterId,
}: {
  clusterName: string | undefined;
  clusterId: string;
}) => {
  const { logEvent } = AnalyticsEventLogger();
  const logEventWithDetails = (type: string) => {
    logEvent('breadcrumbs-navigation', { type, clusterId });
  };
  const path = useAccountIdRoute('/orgs/:orgId/accounts/:accountId/clusters');
  const clusterOverviewLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}?view=arsigs-overview`,
  );

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
      <Breadcrumbs.Divider />
      <RouterLink to={path}>
        <Breadcrumbs.Item
          onClick={() => {
            logEventWithDetails('clusters-view-page');
          }}
        >
          Clusters
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />
      {clusterName ? (
        <RouterLink to={clusterOverviewLink}>
          <Breadcrumbs.Item
            active={true}
            id="cluster-name"
            onClick={() => {
              logEventWithDetails('clusters-details-page');
            }}
          >
            {clusterName}
          </Breadcrumbs.Item>
        </RouterLink>
      ) : (
        <SkeletonText className="w-[200px]" />
      )}
    </Breadcrumbs>
  );
};

export const UpdateK8sConnectorBreadcrumbsHeader = ({
  clusterName,
  clusterId,
}: {
  clusterName: string;
  clusterId: string;
}) => {
  const { logEvent } = AnalyticsEventLogger();
  const logEventWithDetails = (type: string) => {
    logEvent('breadcrumbs-navigation', { type, clusterId });
  };
  const clustersPath = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/clusters',
  );
  const clusterOverviewLink = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}?view=arsigs-overview`,
  );
  const updateK8sConnectorPath = useAccountIdRoute(
    `/orgs/:orgId/accounts/:accountId/clusters/${clusterId}/update-k8s-connector`,
  );

  return (
    <Breadcrumbs className="pt-8">
      <Breadcrumbs.Item>Risk Ledger</Breadcrumbs.Item>
      <Breadcrumbs.Divider />
      <RouterLink to={clustersPath}>
        <Breadcrumbs.Item
          onClick={() => {
            logEventWithDetails('clusters-view-page');
          }}
        >
          Clusters
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />

      <RouterLink to={clusterOverviewLink}>
        <Breadcrumbs.Item
          id="cluster-name"
          onClick={() => {
            logEventWithDetails('clusters-details-page');
          }}
        >
          {clusterName}
        </Breadcrumbs.Item>
      </RouterLink>
      <Breadcrumbs.Divider />

      <RouterLink to={updateK8sConnectorPath}>
        <Breadcrumbs.Item
          active={true}
          id="update-k8s-connector"
          onClick={() => {
            logEventWithDetails('update-k8s-connector-page');
          }}
        >
          Update K8s Connector
        </Breadcrumbs.Item>
      </RouterLink>
    </Breadcrumbs>
  );
};
