import { CaptureOptions, posthog, Properties } from 'posthog-js';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type EventCapturer = (
  event_name: string,
  properties?: Properties,
  options?: CaptureOptions,
) => void;

type OptTracking = () => void;

type PersonIdentifier = (userId: string, email?: string, name?: string) => void;

type PostHogContextProps = {
  person?: { userId?: string; email?: string; name?: string };
  isPostHogLoaded?: boolean;
  isUserIdentified?: boolean;
  phCaptureEvent?: EventCapturer;
  phIdentify?: PersonIdentifier;
  phReset?: () => void;
  phOptInTracking?: OptTracking;
  phOptOutTracking?: OptTracking;
};

const initialState: PostHogContextProps = {
  person: {},
  isPostHogLoaded: false,
  isUserIdentified: false,
  phIdentify: () => {},
  phCaptureEvent: () => {},
  phReset: () => {},
  phOptInTracking: () => {},
  phOptOutTracking: () => {},
};

const PostHogContext = createContext(initialState);

type PostHogProviderProps = {
  children: ReactNode;
  phCaptureEvent?: (
    event_name: string,
    properties?: Properties,
    options?: CaptureOptions,
  ) => {};
  phIdentify?: (userId: string, name: string, email: string) => {};
  phReset?: () => {};
  phOptInTracking?: OptTracking;
  phOptOutTracking?: OptTracking;
};

function PostHogProvider(args: PostHogProviderProps) {
  const [state, setState] = useState<PostHogContextProps>(initialState);

  const location = useLocation();
  const onPostHogLoaded = (postHog: typeof posthog) => {
    optInTracking();

    setState((prevState) => {
      return {
        ...prevState,
        ['isPostHogLoaded']: true,
      };
    });
  };
  const onIdentification = (userId: string, account_id?: string) => {
    posthog?.identify(userId, {
      userId: userId,
      accountId: account_id,
    });
    setState((prevState) => {
      return {
        ...prevState,
        ['isUserIdentified']: true,
      };
    });
  };
  const onReset = () => {
    if (state.isPostHogLoaded && state.isUserIdentified) {
      posthog.reset();
      posthog.opt_out_capturing();
    }
  };
  const onCaptureEvent = (
    event_name: string,
    properties?: Properties,
    options?: CaptureOptions,
  ) => {
    if (state.isPostHogLoaded) {
      posthog.capture(event_name, properties, options);
    }
  };
  const optInTracking = () => {
    posthog.clear_opt_in_out_capturing();
    posthog.opt_in_capturing();
  };
  const optOutTracking = () => {
    posthog.opt_out_capturing();
  };

  useEffect(() => {
    if (
      import.meta.env.VITE_ENABLE_ANALYTICS === 'true' &&
      import.meta.env.VITE_POSTHOG_TOKEN
    ) {
      if (!state.isPostHogLoaded) {
        posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
          debug: false,
          persistence: 'localStorage',
          persistence_name: 'chkk_analytics',
          enable_recording_console_log: true,
          autocapture: true,
          ip: true,
          loaded: onPostHogLoaded,
          api_host: `${window.location.origin}/ingest`,
          session_recording: {
            maskTextSelector: '.sensitive',
          },
        });
      }
    }
    setState((prevState) => {
      return {
        ...prevState,
        ['phCaptureEvent']: onCaptureEvent,
        ['phIdentify']: onIdentification,
        ['phReset']: onReset,
        ['phOptInTracking']: optInTracking,
        ['phOptOutTracking']: optOutTracking,
      };
    });
  }, [
    args.phReset,
    args.phCaptureEvent,
    args.phIdentify,
    args.phOptInTracking,
    args.phOptOutTracking,
  ]);

  // PostHog requires SPA to capture pageviews explicitly
  useEffect(() => {
    onCaptureEvent('$pageview', {
      pathname: location.pathname,
      key: location.key,
    });
  }, [location]);

  return (
    <PostHogContext.Provider value={state}>
      {args.children}
    </PostHogContext.Provider>
  );
}

export { PostHogContext, PostHogProvider };
